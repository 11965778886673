import React, { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { convertDateForFront } from "@layout/modals/utils";
import { navigate } from "gatsby";

import Confetti from "@/components/common/confetti";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";

import AvatarBlock from "./avatarBlock";
import { getAccountPageData } from "./graphql/__generated__/getAccountPageData";
import { GET_ACCOUNT_PAGE_DATA } from "./graphql/GET_ACCOUNT_PAGE_DATA";
import EmailBlock from "./loginTypeBlocks/emailBlock";
import PhoneBlock from "./loginTypeBlocks/phoneBlock";
import Personal from "./personal";
import "./styles.scss";

const Account = () => {
  const {
    isUserLoggedIn,
    setPhone,
    setTmpPhone,
    name,
    setName,
    setEmail,
    setDateOfBirth,
    setAnonymous,
    setGender,
    setIsEmailVerified,
  } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);
  const [showAnimationWithConfetti, setShowAnimationWithConfetti] = useState(false);

  const {
    data,
    error,
    loading,
    refetch: refetchAccountPageData,
  } = useQuery<getAccountPageData>(GET_ACCOUNT_PAGE_DATA);

  useEffect(() => {
    if (!isUserLoggedIn || isExpert) {
      if (isBrowser()) {
        navigate("/", { replace: true });
      }
    }
  }, [isExpert, isUserLoggedIn]);

  useEffect(() => {
    const resetConfettiAnimation = showAnimationWithConfetti && setTimeout(() => {
      setShowAnimationWithConfetti(false);
    }, 3000);

    return () => {
      if (resetConfettiAnimation) {
        clearTimeout(resetConfettiAnimation);
      }
    };
  }, [showAnimationWithConfetti]);

  useEffect(() => {
    if (data && !error && !loading) {
      const {
        email,
        phone: savedPhone,
        tmpPhone,
        isEmailVerified,
        user: {
          name: savedName, dateOfBirth, anonymous, gender,
        },
      } = data.getMyProfile;

      if (email) {
        setEmail(email);
      }
      if (savedPhone) {
        setPhone(savedPhone);
      }
      if (tmpPhone) {
        setTmpPhone(tmpPhone);
      }
      setName(savedName);
      setDateOfBirth(convertDateForFront(dateOfBirth));
      setIsEmailVerified(!!isEmailVerified);
      setAnonymous(!!anonymous);
      if (gender) {
        setGender(gender);
      }
    }
  }, [
    data,
    error,
    loading,
    setAnonymous,
    setDateOfBirth,
    setEmail,
    setGender,
    setIsEmailVerified,
    setName,
    setPhone,
  ]);

  if (loading) {
    return (
      <div className="account">
        <h1 className="account__header">Аккаунт</h1>
        {/* todo: replace with skeleton */}
        <Loader />
      </div>
    );
  }

  if (!data || !isUserLoggedIn || isExpert) {
    return null;
  }
  return (
    <>
      {showAnimationWithConfetti && <Confetti />}
      <div className="account">
        <h1 className="account__header">Аккаунт</h1>
        <div className="account__id-block">
          <div className="account__id">{`ID${data.getMyProfile.user.id}`}</div>
          <Tooltip text="Скопировано" position={TooltipPositionEnum.Top}>
            <div
              className="a account__id-copy"
              onClick={() => {
                navigator.clipboard.writeText(`ID${data.getMyProfile.user.id}`);
              }}
            >
              <Icon type={IconTypeEnum.Copy} size={IconSizeEnum.Size20} />
              <div>Копировать</div>
            </div>
          </Tooltip>
        </div>
        <AvatarBlock
          mobileImage={data.getMyProfile.user.mobileImage}
          desktopImage={data.getMyProfile.user.desktopImage}
          name={name}
          userId={data.getMyProfile.user.id}
          setShowAnimationWithConfetti={setShowAnimationWithConfetti}
        />
        <Personal userData={data.getMyProfile.user} setShowAnimationWithConfetti={setShowAnimationWithConfetti} />
        <EmailBlock setShowAnimationWithConfetti={setShowAnimationWithConfetti} />
        <PhoneBlock
          successHandler={refetchAccountPageData}
          setShowAnimationWithConfetti={setShowAnimationWithConfetti}
          changePhoneInSeparateModalFlag
        />
      </div>
    </>
  );
};

export default Account;
