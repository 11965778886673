import React, { useState } from "react";

import _uniqueId from "lodash/uniqueId";

import { OptionToggleProps } from "./types";
import "./styles.scss";

const OptionToggle = (props: OptionToggleProps) => {
  const {
    isChecked, optionOn, optionOff, onChange,
  } = props;
  const [inputUniqueId] = useState(_uniqueId());

  return (
    <div className="option-toggle">
      <input
        type="checkbox"
        id={`option-toggle${inputUniqueId}`}
        checked={isChecked}
        onChange={() => {
          if (onChange) {
            onChange(!isChecked);
          }
        }}
      />
      <label htmlFor={`option-toggle${inputUniqueId}`}>
        <span>{optionOn}</span>
        <span>{optionOff}</span>
      </label>
    </div>
  );
};

export default OptionToggle;
